@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '~@fortawesome/fontawesome-free/css/all.css';

@font-face {
  font-family: 'Kraft Nine';
  src: url('./assets/fonts/KraftNineCARO.ttf');
}

@font-face {
  font-family: 'Consola';
  src: url('./assets/fonts/consola.ttf');
}

@font-face {
  font-family: 'A Love of Thunder';
  src: url('./assets/fonts/A\ Love\ of\ Thunder.ttf');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets//fonts/BebasNeue-Regular.woff2'),
    url('./assets/fonts/BebasNeue-Regular.woff'),
    url('./assets/fonts/BebasNeue-Regular.ttf');
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link,
a:visited {
  text-decoration: none;
}

.slick-slide div {
  outline: none !important;
}

.magnifier {
  max-height: 100%;
}

.magnifier-image {
  /* object-fit: contain; */
}

.magnifying-glass {
  /* object-fit: contain; */
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85),
    0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
}
